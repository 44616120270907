<template>
  <div>
    <a-card :bordered="false" id="change-password" class="header-solid mb-24">
      <template #title>
        <h5 class="mb-0 font-semibold">Сменить пароль</h5>
      </template>
      <a-form
          :hideRequiredMark="true"
          @submit.prevent="validatePassword"
      >
        <a-row :gutter="[24]">
          <a-col :span="24">
            <a-form-item
                class="mb-10"
                label="Новый пароль"
                :colon="false"
                :validateStatus="passwordError ? 'error' : ''"
                :help="passwordError"
            >
              <a-input-password
                  placeholder="Новый пароль"
                  v-model="newPassword"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
                class="mb-10"
                label="Подтвердите новый пароль"
                :colon="false"
                :validateStatus="confirmPasswordError ? 'error' : ''"
                :help="confirmPasswordError"
            >
              <a-input-password
                  placeholder="Подтвердите новый пароль"
                  v-model="confirmPassword"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <p class="text-muted mt-1">
          Пароль должен содержать:
          <ul>
            <li>Минимум 8 символов</li>
            <li>Хотя бы одну цифру</li>
            <li>Хотя бы один специальный символ (!@#$%^&*)</li>
            <li>Без кириллических символов</li>
          </ul>
        </p>
        <a-row :gutter="[24]">
          <a-col :span="24" :lg="12">
          </a-col>
          <a-col :span="24" :lg="12" class="text-right">
            <a-button
                type="primary"
                class="px-25"
                @click="validatePassword"
            >
              ОБНОВИТЬ ПАРОЛЬ
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import axios from "@/configs/axios";

export default {
  name: "PasswordReset",
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      passwordError: null,
      confirmPasswordError: null
    };
  },
  methods: {
    ...mapActions({
      CHANGE_PASSWORD:'user/CHANGE_PASSWORD',
    }),
    async validatePassword() {
      this.passwordError = null;
      this.confirmPasswordError = null;

      const uidb64 = this.$route.query.uidb64;
      const token = this.$route.query.token;
      console.log('this.$route.params', this.$route.params)

      // Проверка длины пароля (минимум 8 символов)
      if (this.newPassword.length < 8) {
        this.passwordError = "Пароль должен содержать минимум 8 символов.";
      }

      // Проверка наличия хотя бы одной цифры
      if (!/\d/.test(this.newPassword)) {
        this.passwordError = "Пароль должен содержать хотя бы одну цифру.";
      }

      // Проверка на наличие хотя бы одного специального символа
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(this.newPassword)) {
        this.passwordError = "Пароль должен содержать хотя бы один специальный символ.";
      }

      // Проверка на наличие кириллических символов
      if (/[а-яА-ЯЁё]/.test(this.newPassword)) {
        this.passwordError = "Пароль не должен содержать кириллические символы.";
      }

      // Проверка на совпадение паролей
      if (this.newPassword !== this.confirmPassword) {
        this.confirmPasswordError = "Пароли не совпадают.";
      }

      if (!this.passwordError && !this.confirmPasswordError) {
        // Логика для обновления пароля
        axios.post(`/organizations/password_reset_confirm/`, {
          new_password:this.newPassword,
          confirm_password:this.confirmPassword,
          uidb64: uidb64,
          token: token,
        }).then(response => {
          this.$message.success("Пароль успешно сброшен!");
          this.$router.push({name:'login'});
        }).catch(error => {
          this.$message.error("Ошибка при сбросе пароля!");
        });

      }
    }
  }
};
</script>

<style scoped lang="scss">

</style>
